<template>
  <div>
    <ApplicationList />

    <BaseModal
      v-model="newAppModalActive"
      :has-modal-card="true"
      :trap-focus="true"
      :destroy-on-hide="false"
      aria-role="dialog"
      aria-label="New application"
      aria-modal
    >
      <CardPopup
        title="New application"
        @hide="newAppModalActive = false"
      >
        <template #body>
          <b-field
          label="Application name*"
          :type="applicationErrors.name ? 'is-danger' : ''"
          :message="applicationErrors.name ? applicationErrors.name : ''"
          >
            <b-input
              v-model="application.name"
              type="text"
              placeholder="e.g. My awesome application"
              @keyup.native="validateApplication('name')"
            />
          </b-field>
          <b-field label="Application description">
            <b-input
              v-model="application.description"
              maxlength="200"
              type="textarea"
              placeholder="e.g. My awesome description"
            />
          </b-field>
          <b-field
            label="Users"
            class="pb-6"
            :type="applicationErrors.shared_with ? 'is-danger' : ''"
            :message="applicationErrors.shared_with ? applicationErrors.shared_with : ''"
          >
            <b-taginput
              v-model="application.shared_with"
              autocomplete
              :data="filteredUsers"
              field="full_name"
              type="is-primary"
              @typing="getFilteredUsers"
            />
          </b-field>
        </template>
        <template #footer>
          <div class="is-flex is-justify-content-space-between w-full">
            <b-button
              class="px-6 rounded-8 btn-primary-light"
              @click="newAppModalActive = false"
            >
              Cancel
            </b-button>
            <b-button
              type="is-primary"
              class="px-6 rounded-8"
              @click="createApplication()"
            >
              Create
            </b-button>
          </div>
        </template>
      </CardPopup>
    </BaseModal>
  </div>
</template>

<script >
import { computed, defineAsyncComponent, onMounted, reactive, ref, watch } from '@vue/composition-api';
import { useCoreApplicationStore } from '@/modules/core/store/coreApplicationStore';
import { createApplicationService } from '@/services/application-service/applicationRequests';
import { fetchUserByIdService, fetchUsersService } from '@/services/user-service/userRequests';
import { useSession } from '@/hooks/vueSession';
import { useRouter } from '@/hooks/vueRouter';
import * as Yup from 'yup';

//-- child components --//
const BaseModal = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/BaseModal.vue'));
const CardPopup = defineAsyncComponent(() => import('@/modules/core/components/generics/base-modal/CardPopup.vue'));
const ApplicationList = defineAsyncComponent(() => import('@/modules/core/components/ApplicationList.vue'));

//-- composable hooks --//
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const coreApplicationStore = useCoreApplicationStore();
  const session = useSession();
  const router = useRouter();

  // -- Application Validation --//
  const ApplicationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required.').min(2, 'Name must be at least 2 characters long.'),
    shared_with: Yup.array().required('Collaborator is required.').min(1, 'Please select at least 1 collaborator.')
  });

  //-- application logic --//
  const application = reactive({
    name: '',
    description: '',
    shared_with: []
  });
  const applicationErrors = reactive({
    name: '',
    shared_with: ''
  });
  const validateApplication = async field => {
    try {
      await ApplicationSchema.validateAt(field, application);
      applicationErrors[field] = '';
    } catch (err) {
      applicationErrors[err.path] = err.message;
    }
  };
  const newAppModalActive = computed({
    get() {
      return coreApplicationStore.isNewApplicationModalActive;
    },
    set(value) {
      coreApplicationStore.setApplicationActivity(value);
    }
  });
  watch(async () => {
    try {
      await ApplicationSchema.validateAt('shared_with', application);
      applicationErrors['shared_with'] = '';
    } catch (err) {
      applicationErrors[err.path] = err.message;
    }
  }, () => newAppModalActive.value, () => {
    if (!newAppModalActive.value) {
      application.name = '';
      application.description = '';
    }
  });
  const createApplication = async () => {
    try {
      await ApplicationSchema.validate(application, {
        abortEarly: false
      });
      try {
        const {
          data: {
            data: appData
          }
        } = await createApplicationService(application);
        newAppModalActive.value = false;
        router.push('/application/' + appData.id + '/modules');
      } catch (error) {
        console.error(error);
      }
    } catch (err) {
      err.inner.reverse().forEach(error => {
        applicationErrors[error.path] = error.message;
      });
    }
  };

  //-- users logic --//
  const filteredUsers = ref([]);
  const fetchedUsers = ref([]);
  const fetchUserDetails = async () => {
    try {
      const {
        data: {
          data: userData
        }
      } = await fetchUserByIdService(session.get('id'));
      application.shared_with.push(addFullName(userData));
    } catch (err) {
      console.error(err);
    }
  };
  const fetchUsersList = async () => {
    try {
      const {
        data: {
          data: userList
        }
      } = await fetchUsersService();
      fetchedUsers.value = userList.map(user => addFullName(user));
    } catch (err) {
      console.error(err);
    }
  };
  const addFullName = user => {
    user.full_name = `${user.first_name} ${user.last_name}`;
    return user;
  };
  const getFilteredUsers = text => {
    filteredUsers.value = fetchedUsers.value.filter(option => {
      return (option.first_name + ' ' + option.last_name).toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
    });
  };
  onMounted(() => {
    fetchUserDetails();
    fetchUsersList();
  });
  return {
    application,
    applicationErrors,
    validateApplication,
    newAppModalActive,
    createApplication,
    filteredUsers,
    getFilteredUsers
  };
};
__sfc_main.components = Object.assign({
  ApplicationList,
  BaseModal,
  CardPopup
}, __sfc_main.components);
export default __sfc_main;
</script>

<style>
.has-light-bg {
  background: #ededed !important;
}

.has-insert-shadow {
  -webkit-box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
  box-shadow: -10px 9px 15px -6px rgba(0, 0, 0, 0.1);
}
</style>
// @ts-check
import { CORE_APPLICATION_STORE_ID } from '@/constants/storeIds';
import { ref } from '@vue/composition-api';
import { defineStore } from 'pinia';

export const useCoreApplicationStore = defineStore(CORE_APPLICATION_STORE_ID, () => {
    const isNewApplicationModalActive = ref(false);
    /**
     * @param {boolean} isModalActive 
     */
    const setApplicationActivity = (isModalActive) => {
        isNewApplicationModalActive.value = isModalActive;
    };

    return {
        isNewApplicationModalActive,
        setApplicationActivity
    };
});
